import { LayoutRef, Shape } from 'modules/shared/components/DraggableLayout';
import { noop } from 'modules/shared/utils';
import { createContext, createRef, MutableRefObject } from 'react';
import { StepType, RuleTypes, ConditionTypes, Views } from '../constants';
import { ConnectionPoint, DefaultValueConfig, Step, StepConfig, Condition } from '../types';

export const WizardContext = createContext<{
  layoutRef: MutableRefObject<LayoutRef>;
  connection: string;
  projectId: string;
  templateId: string;
  selectedStep: string;
  steps: Step[];
  stepData: Step | undefined;

  isImportSourceModalOpen: boolean;
  selectedImportShape: string;

  editableTansformation: string;
  editableCombineColumn: string;
  isUpdateTransformationModalOpen: boolean;
  draggableItem: ConnectionPoint;
  isBlendConfigModalOpen: boolean;
  isAddColumnTypeModalOpen: boolean;
  currentAddColumn: string;
  baseInfoLoading: boolean;
  currentDefaultValueConfig: DefaultValueConfig;
  isEditDefaultValueModalOpen: boolean;
  isDraft: boolean;
  isUpdateCombineColumnOpen: boolean;
  isUpdateConditionModalOpen: boolean;
  editableCondition: string;
  jsonConfigs: {
    config: string | null;
    mappings: string | null;
  };
  view: Views;
  latestMappingsBoardConfig: {
    steps: Step[];
    selectedStep: string;
  };
  publishing: boolean;
}>({
  publishing: false,
  isAddColumnTypeModalOpen: false,
  layoutRef: createRef() as MutableRefObject<LayoutRef>,
  connection: '',
  projectId: '',
  templateId: '',
  selectedStep: '',
  steps: [],
  stepData: undefined,
  isBlendConfigModalOpen: false,
  isImportSourceModalOpen: false,
  isUpdateTransformationModalOpen: false,
  isUpdateCombineColumnOpen: false,
  isUpdateConditionModalOpen: false,
  editableCondition: '',
  selectedImportShape: '',
  editableTansformation: '',
  draggableItem: {
    parentId: '',
    title: '',
  },
  currentAddColumn: '',
  baseInfoLoading: false,
  currentDefaultValueConfig: {
    shapeId: '',
    col: '',
  },
  isEditDefaultValueModalOpen: false,
  isDraft: false,
  editableCombineColumn: '',
  jsonConfigs: {
    config: null,
    mappings: null,
  },
  view: Views.BOARD,
  latestMappingsBoardConfig: {
    steps: [],
    selectedStep: '',
  },
});

export const WizardHandlersContext = createContext<{
  createStep: (type: StepType) => void;
  deleteStep: (idx: string) => void;
  selectStep: (idx: string) => void;
  updateShape: (id: string, shape: Partial<Shape>) => void;
  setupInputShape: (id: string, shape: Partial<Shape>) => Promise<void>;
  refreshCard: (id: string) => void;
  openImportSourceModal: (id: string) => void;
  closeImportSourceModal: () => void;

  openBlendConfigModal: () => void;
  closeBlendConfigModal: () => void;

  openUpdateTransformationModal: (shapeId: string) => void;
  closeUpdateTransformationModal: () => void;
  openUpdateCombineModalModal: (shapeId: string) => void;
  closeUpdateCombineModalModal: (arg?: { skipDelete: boolean }) => void;

  addTransformation: (config: {
    x: number;
    y: number;
    connectionId: string;
    type: RuleTypes | ConditionTypes;
    config: Record<string, any>;
  }) => void;
  updateTransformation: (shapeId: string, type: RuleTypes | ConditionTypes, config: Record<string, any>) => void;
  deleteTransformation: (shapeId: string) => void;

  deleteCondition: (shapeId: string) => void;
  openUpdateConditionModal: (shapeId: string, conditionType?: 'if' | 'ifElse') => void;
  closeUpdateConditionModal: () => void;

  updateCondition: (shapeId: string, conditions: Condition[], conditionType: 'if' | 'ifElse') => void;

  addCondition: (config: {
    x: number;
    y: number;
    connectionId: string;
    conditions: Condition[];
    conditionType: 'if' | 'ifElse';
  }) => void;

  addCombineColumn: (config: { x: number; y: number; connectionId: string; title: string }) => void;
  updateCombineColumn: (shapeId: string, title: string) => void;
  deleteCombineColumn: (shapeId: string) => void;

  resetInputCard: (shapeId: string) => void;
  updateDraggableItem: (item: ConnectionPoint) => void;
  createConnection: (start: ConnectionPoint, end: ConnectionPoint) => void;
  deleteConnectionChain: (connectionId: string) => void;
  updateStepConfig: (config: Partial<StepConfig>) => void;

  openAddColumnTypeModal: (addCol: string) => void;
  closeAddColumnTypeModal: () => void;
  resetState: () => void;
  setState: (steps: Step[], selectedStep: string) => void;
  openEditDefaultValueModal: (config: DefaultValueConfig) => void;
  closeEditDefaultValueModal: () => void;

  updateDefaultValue: (shapeId: string, col: string, value: string) => void;
  changeView: (view: Views) => void;
  publish: (steps: Step[], selectedStep: string, templateId: string) => void;
}>({
  publish: noop,
  createStep: noop,
  deleteStep: noop,
  selectStep: noop,
  updateShape: noop,
  setupInputShape: noop,
  refreshCard: noop,
  openImportSourceModal: noop,
  closeImportSourceModal: noop,
  openBlendConfigModal: noop,
  closeBlendConfigModal: noop,

  openUpdateTransformationModal: noop,
  closeUpdateTransformationModal: noop,
  addTransformation: noop,
  updateTransformation: noop,
  deleteTransformation: noop,

  deleteCondition: noop,
  openUpdateConditionModal: noop,
  closeUpdateConditionModal: noop,
  updateCondition: noop,

  addCondition: noop,

  addCombineColumn: noop,
  updateCombineColumn: noop,
  deleteCombineColumn: noop,

  resetInputCard: noop,
  updateDraggableItem: noop,
  createConnection: noop,
  deleteConnectionChain: noop,
  updateStepConfig: noop,
  openAddColumnTypeModal: noop,
  closeAddColumnTypeModal: noop,
  resetState: noop,
  setState: noop,
  openEditDefaultValueModal: noop,
  closeEditDefaultValueModal: noop,
  updateDefaultValue: noop,

  openUpdateCombineModalModal: noop,
  closeUpdateCombineModalModal: noop,

  changeView: noop,
});
